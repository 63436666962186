<template>
  <div class="brandContent">
    <!-- headerbar部分 -->
    <div class="img">
      <img src="../../assets/backImg.jpg" alt="" />
      <div class="title">
        <commonT :value="titleConent" class="commonT" />
      </div>
    </div>
    <!-- 内容部分 -->
    <!-- 团队内容 -->
    <div class="brandlist">
      <div class="brandItem" v-for="(item, index) in brandList" :key="index">
        <img
          :src="
            item.brandImg && item.brandImg.length > 0
              ? item.brandImg[0].url
              : ''
          "
          alt=""
        />
        <div class="brandTitle">
          <p>{{ item.brandName }}</p>
          <p>{{ item.brandIntroduction }}</p>
        </div>
      </div>
    
    </div>
      <div class="pagination">
        <van-pagination
          v-model="pageIndex"
          :total-items="total"
          :items-per-page="pageSize"
          @change="getchangeIndex"
          :show-page-size="3"
          force-ellipses
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
        </van-pagination>
      </div>
      <FooterContainer />
  </div>
</template>
<script>
export default {
  name: "M-Brand",
  data() {
    return {
      titleConent: {
        name: "品牌",
        ename: "Brand",
      },
      brandList: [],
      pageIndex: 1,
      pageSize: 3,
      total: 0,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      let result = await this.$api.getBrandList(this.pageIndex, this.pageSize);
      this.total = Number(result.page.totalCount);
      let res = result.data;
      if (res.length > 0) {
        res.forEach((item) => (item.brandImg = JSON.parse(item.brandImg)));
      }
      this.brandList = res;
    },
    //索引切换
    getchangeIndex(i) {
      this.pageIndex = i;
      this.initData();
    },
  },
};
</script>
<style lang="scss" scoped>
.brandContent {
  height: 100%;
  .img {
    width: 100%;
    height: 165px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .title {
      width: 80%;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 64px;
      box-sizing: border-box;
      background-color: #fff;
      &::before {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-color: #ddd;
      }

      .commonT {
        width: 100%;
        padding-left: 20px;
      }
    }
  }

  .brandlist {
    width: 90%;
    min-height: 500px;
    margin: 0 auto;
    box-sizing: border-box;
    // padding: 0 30px;
    .brandItem {
        // height: 190px;
      padding: 20px 10px;
      display: flex;
      align-items: center;
      position: relative;
      margin-top: 20px;
      box-sizing: border-box;
      // border-radius: 20px;
      //   background-color: rgb(246, 246, 246);
      img {
        width: 165px;
        height: 80px;
      }
      .brandTitle {
        
        box-sizing: border-box;
        margin-left: 10px;
        flex: 1;
        p {
          text-align: left;
          &:first-of-type {
            font-size: 18px;
            margin:5px 0;
          }
          &:last-of-type {
            font-size: 0.34667rem;
            color: #bebac4;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-wrap;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: verticaL;
          }
        }
      }
    }
  
  }
}
  .pagination {
      margin: 40px 0;
    }
</style>